<template>

</template>

<script>
import Vue from "vue";

export default {
  data () {
    return {
      dataForm: {}
    }
  },
  created () {
    this.getLoginUser()
  },
  methods: {
    getLoginUser () {
      let query = window.location.href.split('?')[1].split('&')
      query.forEach(item => {
        this.dataForm[item.split('=')[0]] = item.split('=')[1]
      })
      for (let key in this.dataForm) {
        this.dataForm[key] = decodeURIComponent(this.dataForm[key])
        if (key == 'homePageVO') {
          this.dataForm[key] = JSON.parse(this.dataForm[key])
        }
      }
      if (this.dataForm.domain) {
        this.$http.changeUrl(this.dataForm.domain)
      }
      Vue.prototype.$globalData.token= this.dataForm.token
      Vue.prototype.$globalData.topToken= this.dataForm.token
      this.$globalData.userInfo = this.dataForm.homePageVO
      this.$router.replace({ path: '/home' })
    }
  }
}
</script>

<style>

</style>
